import React from 'react'
import Layout from "../components/layout"
import vigilante from '../images/vigilante.png'
import dogberry from '../images/dogberry-day-4.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Men seen looking in windows, citizens urged to take precautions" image={vigilante} />
            <h1>
                Men seen looking in windows, citizens urged to take precautions
            </h1>
            <img src={vigilante} />
            <p>Citizens of Messina are advised to shut their blinds tightly after several unknown men were spotted peering into windows of private residences late last night. The incidents occurred between midnight and 1:00 a.m.</p> 

            <p>At a press conference that he called this morning, Dogberry, local private eye and security guard, expressed concern. “All kinds of people seen them peeping in windows,” he said.</p>

            <p>Dogberry advises all residents to continue to be “extra vigilante.” While vigilance is prudent, the Messina Messenger would like to state that we do not support vigilantism.</p>
            
            <img className="dogberry-ad" src={dogberry} />
        </Layout>
    )
}
